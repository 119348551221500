/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "/src/assets/css/variables";

a[disabled],
button[disabled],
[ion-button][disabled] {
  cursor: default;
  opacity: .4;
  pointer-events: none;
}

//予約完了画面の、予約キャンセル画面
.cancel-alert {
  background-color: rgba(0, 0, 0, 0.7);
  max-width: none !important;
  max-height: none !important;
  --max-width: none !important;
  --max-height: none !important;

  .alert-wrapper {
    background-color: $white !important;
    width:calc(100vw - #{heightExchange(104)}) !important;
    height:calc(100% - #{heightExchange(220)}) !important;
    border-radius: heightExchange(60) !important;
    margin-bottom: calc(#{heightExchange(30)} + env(safe-area-inset-bottom)) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .alert-head {
      width: 100%;
      height: heightExchange(450);
      background-image: url(/assets/img/ukedon-cancel.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin: heightExchange(100) 0 heightExchange(240);
    }

    .alert-message {
      font-size: min(#{heightExchange(90)}, #{widthExchange(90)});
      text-align: center;
      color: $black;
      padding-bottom: 0;
      padding: 0;
    }

    .alert-button-group {
      margin-top: heightExchange(135);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      button {
        &:first-child {
          font-size: min(#{heightExchange(70)}, #{widthExchange(70)});
          background-color: $c-primary;
          box-shadow: 0px 1.5px 3.3px 0px rgba(43, 43, 43, 0.4);
          width: heightExchange(710);
          height: heightExchange(150);
          color: $black;
          border-radius: heightExchange(75);
          margin-top: heightExchange(60);
        }

        &:last-child {
          width:calc(100% - #{heightExchange(50)});
          margin: 0 auto;
          font-weight: bold;
          box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.3);
          height: heightExchange(240);
          border-radius: heightExchange(120);
          background-color: #FF0000;
          color: $white;
          font-size: min(#{heightExchange(90)}, #{widthExchange(90)});
          letter-spacing: 0;

          span {
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

//バージョンアップ失敗画面
.updateFailed {
  width: 100%;
  height: 100%;
  background-color: rgba(248, 246, 246, 0.7);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 0%;
      height: 0%;
      padding: 0;
    }

    .alert-message {
      color: white;
      font-size: min(#{heightExchange(80)}, #{widthExchange(80)});
      text-align: center;
      padding: 0;
      margin-top: 30px;
      font-family: NotoSansCJK;
      font-weight: bold;
    }

    .alert-button-group {
      margin-top: heightExchange(210);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      button {
        width: heightExchange(520);
        margin: 0 auto;
        font-weight: unset;
        height: heightExchange(150);
        border-radius: heightExchange(120);
        background-color: white;
        color: black;
        font-size: min(#{heightExchange(78)}, #{widthExchange(78)});
        letter-spacing: 0;
      }
    }
  }
}

//バージョンアップのボタン無し画面
.versionUpNoButton {
  width: 100%;
  height: 100%;
  background-color: rgba(248, 246, 246, 0.7);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 0%;
      height: 0%;
      padding: 0;
    }

    .alert-message {
      color: white;
      font-size: min(#{heightExchange(80)}, #{widthExchange(80)});
      text-align: center;
      padding: 0;
      font-family: NotoSansCJK;
      font-weight: bold;
      line-height: 1.5;
    }
  }
}

//更新中画面
.versionUpdating {
  width: 100%;
  height: 100%;
  background-color: rgb(248 246 246 / 60%);

  .alert-wrapper {
    width: 90%;
    height: heightExchange(770);
    background-color: #ffb1ce !important;
    border-radius: heightExchange(60) !important;
    max-width: none !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -45px;
    box-shadow: unset;

    .alert-head {
      width: 100%;
      height: 70px;
      padding: 0;
      margin-top: 20px;
    }

    .alert-title {
      font-size: min(#{heightExchange(80)}, #{widthExchange(80)}) !important;
      color: white !important;
      display: flex;
      justify-content: center;
      font-weight: bold;
      font-family: NotoSansCJK
    }

    @media screen and(min-width: 390px) {
      .alert-message {
        margin-top: 20px;
      }
    }

    .alert-message {
      width: 100%;
      height: 40%;
      background-image: url(/assets/img/loading.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      animation: spin 0.8s linear 0s infinite;
    }

    //ローディングのクルクルのアニメーション
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    .alert-button-group {
      height: 0;
      width: 0;
    }
  }
}


.alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;
}

.alertBox {
  z-index: 20002;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .alert-wrapper {
    justify-content: space-evenly;
    position: relative;

    .alert-head {
      bottom: heightExchange(1277);
      left: 0;
      margin: 0;
      padding: 0;
    }

    .alert-message {
      line-height: 130%;
      //height:heightExchange(678);
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .alert-button-group {
      height: heightExchange(568);
      margin: 0;
      flex-direction: column;
      width: 100%;
      justify-content: flex-end;

      .buttonClose {
        font-size: heightFixExchange(70);
        background-color: $c-primary;
        box-shadow: 0px 1.5px 3.3px 0px rgba(43, 43, 43, 0.4);
        width: heightExchange(566);
        height: heightExchange(150);
        color: $black;
        border-radius: heightExchange(75);
        margin-top: heightExchange(50);
        margin-bottom: heightExchange(50);
        font-weight: normal;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          width: heightFixExchange(47);
          height: heightFixExchange(47);
          margin-right: heightFixExchange(40);
          background-image: url(/assets/img/icon-close2.png);
          background-size: contain;
          display: block;
        }
      }

      .buttonBlue {
        justify-content: flex-end;
        width:calc(100% - #{heightExchange(94)});
        margin: 0 auto;
        font-weight: bold;
        box-shadow: 0px 7px 17px 0px rgba(0, 0, 0, 0.3);
        height: heightExchange(240);
        border-radius: heightExchange(120);
        background-color: $c-button-blue;
        color: $white;
        font-size: heightFixExchange(90);
        letter-spacing: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        &.buttonPhone::before {
          content: '';
          width: heightFixExchange(95);
          height: heightFixExchange(126);
          margin-right: heightFixExchange(30);
          margin-top: heightFixExchange(10);
          background-image: url(/assets/img/icon-call.png);
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.alertBoxComingCar {
  .alert-wrapper {
    .alert-head {
      bottom: heightExchange(1024);
      height: min(#{heightExchange(900)}, #{widthExchange(900)});
      background-image: none;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      img {
        width: heightExchange(806);
      }
    }

    .alert-message {
      height: heightExchange(400);
      flex-direction: column;

      p {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: bold;
        margin: 0 0 heightExchange(32);

        span {
          display: block;
          line-height: 100%;
          margin-bottom: heightExchange(12);
        }

        strong {
          display: block;
          line-height: 100%;
          color: #ff6ab2;
          font-size: heightFixExchange(130);
          margin-right: heightExchange(20);
        }
      }
    }
  }
}

.alertBoxNotFound {
  .alert-wrapper {
    .alert-head {
      height: min(#{heightExchange(500)}, #{widthExchange(500)});
      background-image: url(/assets/img/ukedon-notfound.png);
    }
  }
}

.alertBoxFinishCancel {
  .alert-wrapper {
    .alert-head {
      height: heightExchange(511);
      background-image: url(/assets/img/ukedon-finishcancel.png);
    }

    .alert-button-group {
      .buttonBlue {
        margin-bottom: heightExchange(208);
      }
    }
  }
}